<template>
  <div id="forgotpassword-view">
    <section class="bg-light ftco-section">
      <div class="d-flex justify-content-center">
        <img
          src="@/assets/site/images/loader.gif"
          v-if="loader === true"
          class="img-fluid  loader-width"
          alt
        />
      </div>
      <div class="container" v-if="loader === false">
        <div class="row flex-lg-row flex-md-row">
          <div class="col-md-5">
            <div style="margin-top: 2rem">
              <div class="card-body bg-dark-gray">
                <h2 class="text-black">Forgot Password</h2>
                <form>
                  <b-alert
                    show
                    :variant="color"
                    v-if="response_message != null"
                  >
                    {{ response_message }}
                  </b-alert>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="email"
                      placeholder="Enter Your Email Address"
                    />
                    <span class="text-danger" v-if="errors.email">{{
                      this.errors.email[0]
                    }}</span>
                  </div>
                  <div class="row mb-1">
                    <!-- /.col -->
                    <div class="col-6 mt-4 mb-2">
                      <button
                        @click="sendForgotPasswordMail"
                        type="button"
                        class="btn btn-primary btn-block px-4"
                      >
                        Send Email
                      </button>
                    </div>
                  </div>
                  <p style="font-size:14px">
                    Don’t have an account?
                    <router-link to="/site/signup"> Sign Up</router-link> |
                    <router-link to="/site" exact style="font-size:14px"
                      >Login</router-link
                    >
                  </p>

                  <!-- /.col -->
                </form>
              </div>
              <!-- /.login-card-body -->
            </div>
          </div>
          <div class="col-md-7">
            <img
              src="@/assets/site/images/login-bg.jpg"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ForgotpasswordView",
  metaInfo: {
    title: "Forgot Password",
    meta: [
      {
        name: "description",
        content:
          "Forgot your password? Enter your email address to receive a reset link. Simple and secure recovery process for your account.",
      },
      {
        name: "keywords",
        content:
          "Forgot password, Password recovery, Email reset link, User authentication, Account support, Reset password, Login assistance, User help, Secure login, Customer service",
      },
    ],
  },
  data() {
    return {
      email: null,
      errors: [],
      response_message: null,
      loader: false,
      color: "success",
    };
  },
  methods: {
    sendForgotPasswordMail() {
      this.errors = [];
      this.response_message = null;
      this.loader = true;
      let apiUrl = process.env.VUE_APP_URL + "customer/password/email";
      let method_type = "POST";
      fetch(apiUrl, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          email: this.email,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          if (data.status === true) {
            this.color = "success";
            this.response_message = data.message;
            this.email = null;
          } else if (data.status === false) {
            this.color = "danger";
            this.response_message = data.message;
          } else {
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          console.log("User Data", "Error : " + err.message, "error");
        });
    },
  },
};
</script>
